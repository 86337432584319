import React from 'react'
import { FaAngleDown } from 'react-icons/all'
import { Theme } from 'src/style/Theme'
import { css } from 'src/style/css'

const Accordion = ({ title, items, isOpen, toggleAccordion }) => {
    return (
        <div
            className="accordion"
            css={(th: Theme) => css`
                background-color: rgb(245, 245, 245);
                padding: 10px;
                border-radius: 10px;
                display: flex;
                flex-direction: column; /* Display content vertically */
                align-items: stretch;
                justify-content: center;
            `}
        >
            <div
                onClick={toggleAccordion}
                css={(th: Theme) => css`
                    display: flex;
                    flex-direction: row; /* Display title and icon horizontally */
                    align-items: center; /* Center items vertically */
                    justify-content: space-between; /* Create space between title and icon */
                    cursor: pointer;
                `}
            >
                <span> {title} </span>
                <span className={`accordion-icon ${isOpen ? 'open' : ''}`}>
                    <FaAngleDown />
                </span>
            </div>
            {isOpen && (
                <ul
                    className="accordion-items"
                    css={(th: Theme) => css`
                        list-style: disc;
                        padding-left: ${th.spacing.md};
                        @media (max-width: ${th.mediaQuery.sm}) {
                            padding-left: ${th.spacing.lg};
                        }
                        margin-top: ${th.spacing.sm};
                        li {
                            line-height: 1.2;
                            font-size: ${th.fontSize.sm};
                            color: ${th.colors.darkGray};
                            margin: 0 0 ${th.spacing.sm};
                        }
                    `}
                >
                    {items.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default Accordion
