import React, { useState } from 'react'
import { PageContext } from 'src/page/types'
import PageHeader from 'src/components/PageHeader'
import Container from 'src/components/Container'
import {
    ServiceQueryResult,
    unwrapServiceFromQueryAndSetLangPath,
    WrappedServiceQueryResult
} from 'src/model/serviceQuery'
import { useTranslateData } from 'src/i18n/useTranslateData'
import { Theme } from 'src/style/Theme'
import { css } from 'src/style/css'
import Layout from 'src/components/Layout'
import { graphql } from 'gatsby'
import CallToAction from 'src/components/CallToAction'
import { t } from 'src/i18n/translation/t'
import { ServicesGrid } from 'src/components/ServicesGrid'
import { Msgs } from 'src/i18n/translation/types'
import {
    SERVICE_ICONS,
    ServiceBigIconWithoutLink
} from '../components/ServiceIcon'
import Accordion from 'src/components/Accordion'
import Button from '../components/ui/kit/Button'

type ServiceDetailProps = {
    data: {
        service: ServiceQueryResult
        services: WrappedServiceQueryResult
    }
    pageContext: PageContext
}

interface Accordion {
    id: number
    title: string
    items: string[]
}

const DescriptionTechnologiesColumn = ({
    descriptionTechnologies,
    toggleAccordion,
    activeIndex
}: {
    descriptionTechnologies: Accordion[]
    toggleAccordion: (index: number) => void
    activeIndex: number | null
}) => {
    return (
        <div
            css={(th: Theme) => css`
                display: grid;
                gap: ${th.spacing.md};
            `}
        >
            {descriptionTechnologies.map((slide, index) => (
                <Accordion
                    key={index}
                    title={slide.title}
                    items={slide.items.map((item) => item)}
                    isOpen={activeIndex === slide.id}
                    toggleAccordion={() => toggleAccordion(slide.id)}
                />
            ))}
        </div>
    )
}

const Content = ({
    service,
    services,
    msgs,
    lang
}: {
    service: ServiceQueryResult
    services: ServiceQueryResult[]
    msgs: Msgs
    lang: string
}) => {
    const {
        title,
        description,
        descriptionItems,
        serviceId,
        descriptionTechnologies,
        download
    } = service
    const tData = useTranslateData()

    const [activeAccordionIndex, setActiveAccordionIndex] = useState(0)
    const toggleAccordion = (index) => {
        setActiveAccordionIndex((prev) => (prev === index ? 0 : index))
    }

    return (
        <>
            <PageHeader>{tData(title)}</PageHeader>
            <Container>
                <div
                    css={(th: Theme) => css`
                        display: flex;
                        justify-content: space-around;
                        @media (max-width: ${th.mediaQuery.md}) {
                            flex-wrap: wrap;
                        }
                    `}
                >
                    <ServiceBigIconWithoutLink
                        icon={SERVICE_ICONS[serviceId]}
                    />
                    <div
                        css={(th: Theme) => css`
                            padding: 0 0 0 ${th.spacing.xl};
                            flex-basis: 60%;
                            @media (max-width: ${th.mediaQuery.md}) {
                                flex-basis: 100%;
                                padding: ${th.spacing.xl} 0 0 0;
                            }
                        `}
                    >
                        <p
                            css={css`
                                white-space: pre-line;
                            `}
                        >
                            {tData(description)}
                        </p>
                        {descriptionItems && (
                            <ul
                                css={(th: Theme) => css`
                                    list-style: disc;
                                    padding-left: ${th.spacing.md};
                                    @media (max-width: ${th.mediaQuery.sm}) {
                                        padding-left: ${th.spacing.lg};
                                    }
                                    li {
                                        line-height: 1.2;
                                        font-size: ${th.fontSize.sm};
                                        color: ${th.colors.darkGray};
                                        margin: 0 0 ${th.spacing.sm};
                                    }
                                `}
                            >
                                {descriptionItems.map((di, i) => (
                                    <li key={i}>{tData(di)}</li>
                                ))}
                            </ul>
                        )}
                        {descriptionTechnologies && (
                            <div
                                css={(th: Theme) => css`
                                    display: grid;
                                    grid-template-columns: repeat(
                                        auto-fit,
                                        minmax(15em, 1fr)
                                    );
                                    gap: ${th.spacing.md} ${th.spacing.xl};
                                `}
                            >
                                {descriptionTechnologies[lang].leftCol.length >
                                    0 && (
                                    <DescriptionTechnologiesColumn
                                        descriptionTechnologies={
                                            descriptionTechnologies[lang]
                                                .leftCol
                                        }
                                        toggleAccordion={toggleAccordion}
                                        activeIndex={activeAccordionIndex}
                                    />
                                )}
                                {descriptionTechnologies[lang].rightCol.length >
                                    0 && (
                                    <DescriptionTechnologiesColumn
                                        descriptionTechnologies={
                                            descriptionTechnologies[lang]
                                                .rightCol
                                        }
                                        toggleAccordion={toggleAccordion}
                                        activeIndex={activeAccordionIndex}
                                    />
                                )}
                            </div>
                        )}
                        {/* TODO pozriet ine moznosti ako osetrit detaily ine ako AI */}
                        {download.text.length > 0 && (
                            <div>
                                <h2>{tData(download.text[0])}</h2>
                                {download.files.length > 0 &&
                                    download.files.map((file, index) => {
                                        const filePath = tData(file.path)
                                        return (
                                            <div
                                                key={index}
                                                css={css`
                                                    display: flex;
                                                    justify-content: space-between;
                                                    align-items: center;
                                                `}
                                            >
                                                <span>
                                                    {tData(file.filename)}
                                                </span>
                                                <a
                                                    href={filePath}
                                                    target={'_blank'}
                                                    download={true}
                                                    rel="noreferrer"
                                                >
                                                    <Button onClick={() => ''}>
                                                        {tData(download.button)}
                                                    </Button>
                                                </a>
                                            </div>
                                        )
                                    })}
                            </div>
                        )}
                    </div>
                </div>
                <ServicesGrid
                    items={services}
                    msgs={msgs}
                    activeService={service}
                />
            </Container>
        </>
    )
}

const ServiceDetail = ({ data, pageContext }: ServiceDetailProps) => {
    const { msgs, lang } = pageContext
    const { service, services } = data
    const allServices = unwrapServiceFromQueryAndSetLangPath(services, lang)
    return (
        <Layout pageCtx={pageContext}>
            <Content
                service={service}
                services={allServices}
                msgs={msgs}
                lang={lang}
            />
            <CallToAction
                text={t(msgs.services.callToAction.text)}
                linkText={t(msgs.services.callToAction.linkText)}
            />
        </Layout>
    )
}

export default ServiceDetail

export const pageQuery = graphql`
    query ServiceDetailByID($id: String!) {
        service(serviceId: { eq: $id }) {
            title {
                sk
                de
                en
            }
            description {
                sk
                de
                en
            }
            descriptionItems {
                sk
                de
                en
            }
            descriptionTechnologies {
                sk {
                    leftCol {
                        id
                        title
                        items
                    }
                    rightCol {
                        id
                        title
                        items
                    }
                }
                de {
                    leftCol {
                        id
                        title
                        items
                    }
                    rightCol {
                        id
                        title
                        items
                    }
                }
                en {
                    leftCol {
                        id
                        title
                        items
                    }
                    rightCol {
                        id
                        title
                        items
                    }
                }
            }
            image {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        placeholder: BLURRED
                        width: 424
                        height: 308
                    )
                }
                name
            }
            download {
                text {
                    sk
                    en
                    de
                }
                files {
                    filename {
                        sk
                        en
                        de
                    }
                    path {
                        sk
                        en
                        de
                    }
                }
                button {
                    sk
                    de
                    en
                }
            }
            serviceId
        }
        services: serviceOrderJson {
            ...Services
        }
    }
`
