import React, { ComponentType } from 'react'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'

type ButtonProps = {
    type?: 'primary' | 'secondary'
    children: string
    onClick: () => void
    enabled?: boolean
    submit?: boolean
    className?: string
}

export type ButtonComp = ComponentType<ButtonProps>

const Button: ButtonComp = ({
    type = 'primary',
    children,
    onClick,
    enabled = true,
    submit = false
}) => (
    <button
        onClick={onClick}
        disabled={!enabled}
        type={submit ? 'submit' : 'button'}
        css={(th: Theme) => css`
            background-color: ${type === 'primary'
                ? th.colors.primary
                : th.colors.secondary};
            color: ${th.colors.white};
            display: inline-block;
            cursor: ${enabled ? 'pointer' : 'auto'};
            outline: 0;
            white-space: nowrap;
            padding: ${th.spacing.sm} ${th.spacing.md};
            font-size: ${th.fontSize.sm};
            font-weight: ${th.fontWeight.semiBold};
            text-transform: uppercase;
            letter-spacing: 1px;
            border: none;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
            border-radius: ${th.borderRadius.sm};
            border-bottom: 3px solid rgba(0, 0, 0, 0.15);
            &:hover {
                opacity: 0.9;
            }
            > * {
                display: inline-block;
                margin: ${th.spacing.xs} 0;
            }
        `}
    >
        {children}
    </button>
)

export default Button
