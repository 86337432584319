import React, { useState } from 'react'
import { Theme } from 'src/style/Theme'
import { css } from 'src/style/css'
import Icon, { IconType } from 'src/icons'
import { SerializedStyles, useTheme } from '@emotion/react'
import useHoverSpring from 'src/hooks/useHoverSpring'
import { animated } from 'react-spring'
import { Link } from 'src/components/Link'

export const SERVICE_ICONS: { [key: string]: IconType } = {
    'custom-software': Icon.Desktop,
    'maintenance-and-extensions': Icon.PlusCircle,
    'quality-assurance': Icon.Check,
    'it-support': Icon.Wrench,
    software: Icon.Polymer,
    mobile: Icon.Mobile,
    web: Icon.Globe,
    nearshoring: Icon.AddUsers,
    'ai-data-management': Icon.Robot
}

const animationDuration = 200

export const getSubsectionPosition = (index: number) => {
    const th = useTheme()
    let position
    switch (index) {
        case 0:
            position = css`
                left: 0;
                top: -${th.fontSize.xs};
            `
            break
        case 1:
            position = css`
                right: 0;
                top: -${th.fontSize.xs};
            `
            break
        case 2:
            position = css`
                left: 0;
                bottom: -${th.fontSize.xs};
            `
            break
        case 3:
            position = css`
                right: 0;
                bottom: -${th.fontSize.xs};
            `
            break
    }

    return css`
        position: absolute;
        ${position}
    `
}

type IconStyles = {
    wrapper?: SerializedStyles
    AnimatedLink?: SerializedStyles
}

const ServiceIcon = ({
    icon: Icon,
    link,
    active = false,
    styles = {
        wrapper: undefined,
        AnimatedLink: undefined
    }
}: {
    icon: IconType
    link: string
    active: boolean
    styles?: IconStyles
}) => {
    const th = useTheme() as Theme
    const [hovering, setHovering] = useState(active)
    const hoverStateIcon = useHoverSpring({
        hovering,
        duration: animationDuration,
        values: {
            from: {
                background: th.colors.lightestGray,
                color: th.colors.darkGray,
                borderColor: th.colors.darkGray
            },
            to: {
                background: th.colors.darkGray,
                color: th.colors.white,
                borderColor: th.colors.gray
            }
        }
    })
    const hoverStateIconBorder = useHoverSpring({
        hovering,
        duration: animationDuration,
        values: {
            from: {
                boxShadow: '0 0 0 0px transparent',
                transform: 'scale(0.8)'
            },
            to: {
                boxShadow: `0 0 0 2px ${th.colors.darkGray}`,
                transform: 'scale(1.1)'
            }
        }
    })
    const AnimatedLink = animated(Link)
    return (
        <div
            css={(th: Theme) => [
                [
                    css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    `,
                    styles.wrapper
                ]
            ]}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(active)}
        >
            <AnimatedLink
                to={link}
                aria-label={Icon.name}
                css={(th: Theme) => [
                    [
                        css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: ${th.fontSize.xl};
                            border-radius: 50%;
                            border: 1px solid ${th.colors.gray};
                            background: ${th.colors.lightestGray};
                            color: ${th.colors.darkGray};
                            padding: 1.5rem;
                            position: relative;
                        `,
                        styles.AnimatedLink
                    ]
                ]}
                style={hoverStateIcon}
            >
                <animated.div
                    css={(th: Theme) => css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 50%;
                        box-shadow: 0 0 0 0px transparent;
                        transform: scale(0.8);
                        width: 100%;
                        height: 100%;
                    `}
                    style={hoverStateIconBorder}
                />
                <Icon />
            </AnimatedLink>
        </div>
    )
}

const ServiceIconWithoutLink = ({
    icon: Icon,
    styles = {
        wrapper: undefined
    }
}: {
    icon: IconType
    styles?: IconStyles
}) => {
    return (
        <div
            css={(th: Theme) => [
                [
                    css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    `,
                    styles.wrapper
                ]
            ]}
        >
            <div
                css={(th: Theme) => [
                    [
                        css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: ${th.fontSize.xxl};
                            border-radius: 50%;
                            border: 1px solid ${th.colors.gray};
                            background: ${th.colors.darkGray};
                            color: ${th.colors.white};
                            padding: 5rem;
                            position: relative;
                        `,
                        styles.AnimatedLink
                    ]
                ]}
            >
                <div
                    css={(th: Theme) => css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        box-shadow: 0 0 0 2px ${th.colors.darkGray};
                        transform: scale(1.1);
                    `}
                />
                <Icon />
            </div>
        </div>
    )
}

export const ServiceBigIconWithoutLink = ({
    icon: Icon
}: {
    icon: IconType
}) => {
    return <ServiceIconWithoutLink icon={Icon} />
}

export const ServiceBigIcon = ({
    icon: Icon,
    link,
    active = false
}: {
    icon: IconType
    link: string
    active: boolean
}) => {
    return <ServiceIcon icon={Icon} link={link} active={active} />
}

export const ServiceSmallIcon = ({
    icon: Icon,
    link,
    positionStyle,
    active = false
}: {
    icon: IconType
    link: string
    positionStyle: SerializedStyles
    active: boolean
}) => {
    const th = useTheme() as Theme
    const AnimatedLink = css`
        font-size: ${th.fontSize.md};
        padding: 1rem;
        @keyframes bounceSmallIcon {
            0% {
                transform: translateY(-2px);
            }

            100% {
                transform: translateY(2px);
            }
        }
        animation-name: bounceSmallIcon;
        animation-duration: ${animationDuration * 4}ms;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    `
    const styles = {
        wrapper: positionStyle,
        AnimatedLink
    }

    return (
        <ServiceIcon icon={Icon} link={link} active={active} styles={styles} />
    )
}
