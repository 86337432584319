import React, { ComponentType } from 'react'
import { Theme } from 'src/style/Theme'
import { css } from 'src/style/css'
import { grid } from 'src/style/mixins'
import { t } from 'src/i18n/translation/t'
import { useTheme } from '@emotion/react'
import Header from 'src/components/Header'
import { ServiceInfo } from 'src/model/serviceType'
import { Msgs } from 'src/i18n/translation/types'
import { Link } from 'src/components/Link'
import { getContext } from 'src/components/MyContext'
import {
    getSubsectionPosition,
    SERVICE_ICONS,
    ServiceBigIcon,
    ServiceSmallIcon
} from 'src/components/ServiceIcon'

const ServiceColumn = ({
    linkText,
    activeService,
    service
}: {
    linkText: string
    activeService?: ServiceInfo
    service: ServiceInfo
}) => {
    const th = useTheme()
    const lang = getContext().getLang()
    const { path, serviceId, title } = service

    const tDesc = service.description[lang]
    const description =
        tDesc.length > 200 ? tDesc.substring(0, 200) + '...' : tDesc

    const isActiveParent = !!(
        activeService &&
        (service.subservices?.some(
            (ss) => ss.serviceId === activeService.serviceId
        ) ||
            service.serviceId === activeService.serviceId)
    )

    const icons = service.subservices.map((icon) => {
        return {
            serviceId: icon.serviceId,
            icon: SERVICE_ICONS[icon.serviceId],
            link: icon.path
        }
    })

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                text-align: center;
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: ${th.spacing.md};
                    position: relative;
                    > *:not(:last-child) {
                        margin-bottom: ${th.spacing.md};
                    }
                    > *:first-child {
                        margin-bottom: ${th.spacing.lg};
                    }
                    @media (min-width: ${th.mediaQuery.sm}) {
                        > *:not(:first-child) {
                            min-height: 42px;
                        }
                    }
                `}
            >
                <div
                    css={css`
                        min-width: 80%;
                        position: relative;
                    `}
                >
                    <ServiceBigIcon
                        link={path}
                        icon={SERVICE_ICONS[serviceId]}
                        active={isActiveParent}
                    />

                    {icons.map((item, idx) => {
                        const position = getSubsectionPosition(idx)

                        const isActive = !!(
                            activeService &&
                            item.serviceId === activeService.serviceId
                        )

                        return (
                            <ServiceSmallIcon
                                key={`subservice-${idx}`}
                                link={item.link}
                                icon={item.icon}
                                positionStyle={position}
                                active={isActive}
                            />
                        )
                    })}
                </div>

                <Header size={3} uppercase>
                    {title[lang]}
                </Header>
                <p>{description}</p>
            </div>
            <Link
                to={path}
                aria-label={linkText}
                css={css`
                    font-size: ${th.fontSize.sm};
                `}
            >
                {linkText}
            </Link>
        </div>
    )
}

type ServicesGridProps = {
    items: ServiceInfo[]
    msgs: Msgs
    activeService?: ServiceInfo
}

export const ServicesGrid: ComponentType<ServicesGridProps> = ({
    items,
    msgs,
    activeService
}) => {
    const homePageMsgs = msgs.homePage

    return (
        <div
            css={(th: Theme) => css`
                ${grid({ minWidth: '15em', gap: th.spacing.md })};
                align-items: stretch;
            `}
        >
            {items.map((i, idx) => {
                return (
                    <ServiceColumn
                        key={`${i.id}-${idx}`}
                        linkText={t(homePageMsgs.moreInfo)}
                        service={i}
                        activeService={activeService}
                    />
                )
            })}
        </div>
    )
}
